import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import JobReducer from './slice/jobSlice';
import fileReducer from './slice/fileSlice';
import cryptoReducer from './slice/cryptoSlice';

export const store = configureStore({
  reducer: {
    jobSlice: JobReducer,
    fileSlice: fileReducer,
    cryptoSlice: cryptoReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
