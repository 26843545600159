import { FC, useState, Fragment, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import BitcoinAddressDerivation from '../views/BitcoinAddressDerivation';
import EthereumAddressDerivation from '../views/EthereumAddressDerivation';
import LitecoinAddressDerivation from '../views/LitecoinAddressDerivation';
import Navbar from './Navbar';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { setCookies } from '../shared/utils';
import { useAppSelector } from '../shared/redux/hooks';
import { RootState } from '../shared/redux/store';
import { toast, ToastContainer } from 'react-toastify';
import AccessDenied from './AccessDenied';
import { user as UserService } from '../shared/services/users';
import { Loader } from '../images/loading';

import 'react-toastify/dist/ReactToastify.css';

const auth0Audience = (window as any)['platformConfig']?.Auth0_Audience;

const isAuth0Error = (error: any): error is { error: string; message: string } => {
  return typeof error === 'object' && error !== null && ('error' in error || 'message' in error);
};

const Dashboard: FC = (): JSX.Element => {
  const [isBTCTabClicked, setIsBTCTabClicked] = useState<boolean>(false);
  const [isETHTabClicked, setIsETHTabClicked] = useState<boolean>(false);
  const [isLTCTabClicked, setIsLTCTabClicked] = useState<boolean>(false);
  const [isTabEnabled, setIsTabEnabled] = useState<boolean>(false);
  const [checkingAccess, setCheckingAccess] = useState<any>(null);

  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, user } =
    useAuth0();
  const history = useHistory();

  useEffect(() => {
    const refreshAccessToken = async () => {
      try {
        // Get the new access token
        const accessToken = await getAccessTokenSilently();

        // Calculate the remaining time until token expiration
        const tokenExpiration = JSON.parse(atob(accessToken.split('.')[1])).exp;
        const currentTime = Math.floor(Date.now() / 1000);
        const timeRemaining = tokenExpiration - currentTime;

        // Schedule the next token refresh just before expiration
        const refreshTimeout = setTimeout(refreshAccessToken, (timeRemaining - 60) * 1000);

        // Clean up the timeout when the component unmounts or the dependencies change
        return () => clearTimeout(refreshTimeout);
      } catch (error) {
        if (
          isAuth0Error(error) &&
          (error.error === 'login_required' || error.message === 'Login required')
        ) {
          if (!isAuthenticated) {
            console.log('User is not authenticated, redirecting to login');
            loginWithRedirect();
          } else {
            console.error('Error refreshing access token');
          }
        }
      }
    };

    // Start the initial token refresh
    refreshAccessToken();
    // eslint-disable-next-line
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const setToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          cacheMode: 'on',
          authorizationParams: {
            audience: auth0Audience,
            scope: 'openid profile email offline_access'
          },
          detailedResponse: true
        });
        setCookies({
          access_token: token.access_token,
          user_id: user?.sub,
          first_name: user?.name,
          last_name: ''
        });
      } catch (error) {
        console.error(error);
      }
    };

    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      setToken();
      const LOGIN_ENABLED_DOMAINS: any = (window as any)['platformConfig']?.LOGIN_ENABLED_DOMAINS;
      const companyName: string = getCompanyName(user?.email);
      if (LOGIN_ENABLED_DOMAINS?.includes(companyName)) {
        setIsTabEnabled(true);
      }
      return;
    }

    const checkAuth = async () => {
      await loginWithRedirect();
    };

    checkAuth();
  }, [isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently, user]);

  const getCompanyName = (email: any) => {
    const temp = email.replace(/.*@/, '').split('.');
    return temp[temp.length - 2];
  };
  const checkUserAccess = async () => {
    await UserService.checkAccess()
      .then(() => setCheckingAccess(true))
      .catch((error: any) => {
        if (error.response && error.response.status === 403) history.push('/accessdenied');
        setCheckingAccess(false);
      });
  };

  useEffect(() => {
    checkUserAccess();
  });

  const errorOnUploadCsv = useAppSelector((state: RootState) => state.fileSlice);

  useEffect(() => {
    if (errorOnUploadCsv && errorOnUploadCsv.error) {
      toast.error(errorOnUploadCsv.error, {
        position: 'top-right',
        autoClose: 12000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      });
    }
  }, [errorOnUploadCsv]);

  if (isLoading) {
    return <Loader />;
  }

  const ProtectedRoute: any = ({ children }: { children: any }) =>
    checkingAccess === null ? (
      <Loader />
    ) : (
      isAuthenticated && checkingAccess && <Fragment>{children}</Fragment>
    );
  return (
    <>
      <Switch>
        <Route
          path="/accessdenied"
          exact
          component={() => (
            <>
              <Navbar
                isBTCTabClicked={isBTCTabClicked}
                setIsBTCTabClicked={setIsBTCTabClicked}
                isETHTabClicked={isETHTabClicked}
                setIsETHTabClicked={setIsETHTabClicked}
                isLTCTabClicked={isLTCTabClicked}
                setIsLTCTabClicked={setIsLTCTabClicked}
                isTabEnabled={false}
              />
              <AccessDenied />
            </>
          )}
        />
        <Route
          path="/"
          exact
          component={() => (
            <ProtectedRoute>
              <Navbar
                isBTCTabClicked={isBTCTabClicked}
                setIsBTCTabClicked={setIsBTCTabClicked}
                isETHTabClicked={isETHTabClicked}
                setIsETHTabClicked={setIsETHTabClicked}
                isLTCTabClicked={isLTCTabClicked}
                setIsLTCTabClicked={setIsLTCTabClicked}
                isTabEnabled={isTabEnabled}
              />
              {isTabEnabled && (
                <BitcoinAddressDerivation
                  isBTCTabClicked={isBTCTabClicked}
                  isETHTabClicked={isETHTabClicked}
                />
              )}
            </ProtectedRoute>
          )}
        />
        <Route
          path="/ethereum"
          exact
          component={() => (
            <ProtectedRoute>
              <Navbar
                isBTCTabClicked={isBTCTabClicked}
                setIsBTCTabClicked={setIsBTCTabClicked}
                isETHTabClicked={true}
                setIsETHTabClicked={setIsETHTabClicked}
                isLTCTabClicked={isLTCTabClicked}
                setIsLTCTabClicked={setIsLTCTabClicked}
                isTabEnabled={isTabEnabled}
              />
              {isTabEnabled && (
                <EthereumAddressDerivation
                  isBTCTabClicked={isBTCTabClicked}
                  isETHTabClicked={isETHTabClicked}
                />
              )}
            </ProtectedRoute>
          )}
        />
        <Route
          path="/litecoin"
          exact
          component={() => (
            <ProtectedRoute>
              <Navbar
                isBTCTabClicked={isBTCTabClicked}
                setIsBTCTabClicked={setIsBTCTabClicked}
                isETHTabClicked={isETHTabClicked}
                setIsETHTabClicked={setIsETHTabClicked}
                isLTCTabClicked={true}
                setIsLTCTabClicked={setIsLTCTabClicked}
                isTabEnabled={isTabEnabled}
              />
              {isTabEnabled && (
                <LitecoinAddressDerivation
                  isBTCTabClicked={isBTCTabClicked}
                  isETHTabClicked={isETHTabClicked}
                />
              )}
            </ProtectedRoute>
          )}
        />
        <Redirect from="*" to path="/" />
      </Switch>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
