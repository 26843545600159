import { Api as api } from '../../utils/axios';
import { Result } from '../../core/Result';
import { IFileService } from './IFileService';

export class FileService implements IFileService {
  async deriveAddress(fileExceute): Promise<Result<any>> {
    try {
      const response = await api({
        'Content-Type': 'multipart/form-data'
      }).post('/jobs/trigger', {
        assetName: fileExceute.assetName,
        blockHeight: fileExceute.blockHeight,
        csv: fileExceute.csv
      });
      return Result.ok<any>(response.data);
    } catch (error: any) {
      if (error?.response?.status === 400) {
        const errorMessage = error?.response?.data?.message;
        return Result.fail<any>(errorMessage);
      }
      return Result.fail<any>('');
    }
  }
  async blobDownload(blobDownloadParams: any) {
    if (blobDownloadParams) {
      const response = await api({
        'Content-Type': 'application/json'
      }).get(
        `file/download?networkName=${blobDownloadParams.network}&fileType=${blobDownloadParams.fileType}&jobId=${blobDownloadParams.jobId}`
      );
      const fileName = blobDownloadParams.fileName;
      const downloadEl = document.createElement('a');
      downloadEl.style.display = 'none';
      document.body.appendChild(downloadEl);
      const blobFile = new Blob([response?.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blobFile);
      downloadEl.href = url;
      downloadEl.download = fileName;
      downloadEl.click();
    }
  }
}
