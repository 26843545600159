import { FC } from 'react';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/GetAppOutlined';
import { fileService } from '../shared/services/file';
interface DownloadButtonProps {
  blobDownloadParams?: any;
}
const DownloadButton: FC<DownloadButtonProps> = ({ blobDownloadParams }): JSX.Element => {
  return (
    <>
      <Button
        sx={{
          width: '116px',
          height: '36px',
          margin: '9px',
          backgroundColor: 'white',
          fontWeight: 'bold',
          border: '1px solid black',
          textTransform: 'none',
          fontSize: '14px',
          borderRadius: '0px',
          '&:hover': {
            background: 'black',
            color: 'white'
          },
          '& .MuiButtonBase-root': {
            background: 'red'
          }
        }}
        onClick={() => (blobDownloadParams ? fileService.blobDownload(blobDownloadParams) : null)}>
        <DownloadIcon sx={{ marginRight: '7px', width: '18px' }} />
        Download
      </Button>
    </>
  );
};

export default DownloadButton;
