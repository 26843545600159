import { FC, useState } from 'react';
import { TableRow as RowOfTable, TableCell, Typography, Box, Alert } from '@mui/material';
import { styled } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DownloadIcon from '@mui/icons-material/GetAppOutlined';
import DownloadButton from './DownloadButton';
import WorkStatusToast from './WorkStatusToast';
import DownloadOutputOptions from './DownloadOutputOptions';
import Tooltip from '@mui/material/Tooltip';
import { FileUploader } from 'react-drag-drop-files';
import { SUPPORTED_ASSETS_NETWORK_NAME } from '../shared/helpers/constants';
import { fileService } from '../shared/services/file';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { OverAllStatus } from '../shared/models/Jobs';

const fileTypes = ['CSV'];

const StyledTableCell = styled(TableCell)(() => ({
  fontSize: '14px',
  padding: '4px',
  height: '54px',
  '&.file': {
    '& >div': {
      div: {
        width: '150px',
        wordWrap: 'break-word'
      }
    }
  }
}));
const StyledAlert = styled(Alert)(() => ({
  width: '100px',
  padding: '0px',
  height: '25px',
  '& div': {
    padding: '2px 0px 0px 20px',
    minWidth: 0,
    overflow: 'auto'
  }
}));
interface TableRowProps {
  jobId: string;
  file: any;
  networkName: string;
  uploadedFilePath: string;
  uploadDateTime: string;
  blockHeight: string;
  walletCount: string;
  status: number;
  standardOutputPath: string;
  aggregateOutputPath: string;
  errorOutputPath: string;
  handleFileUpload: (file: any) => void;
}

enum FileType {
  uploadFile = 'uploadfile',
  template = 'templateFile',
  error = 'errorFile',
  output = 'outputFile',
  aggregatedOutput = 'aggregatedOutputFile'
}

const TableRow: FC<TableRowProps> = ({
  jobId,
  file,
  networkName,
  uploadedFilePath,
  uploadDateTime,
  blockHeight,
  walletCount,
  status,
  handleFileUpload,
  errorOutputPath
}): JSX.Element => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const dateAsString = new Date(uploadDateTime).toString();
  const timeZone = dateAsString.substring(
    dateAsString.indexOf(')'),
    dateAsString.lastIndexOf('(') + 1
  );
  const cutTimeZone = timeZone
    .split(' ')
    .map((str: string) => str.charAt(0))
    .join('');
  const cutMonth = dateAsString.slice(4, 7);
  const cutDay = dateAsString.slice(8, 10);
  const cutYearAndTime = dateAsString.slice(13, 24);
  const formattedDateWithTimezone = `${cutDay} ${cutMonth} ${cutYearAndTime} ${cutTimeZone}`;
  const stdOutputfileDownloadParams = {
    network: networkName,
    fileType: FileType.output,
    jobId: jobId,
    fileName: `${jobId}_${networkName}_calculated_balances`
  };
  const aggregateOutputfileDownloadParams = {
    network: networkName,
    fileType: FileType.aggregatedOutput,
    jobId: jobId,
    fileName: `${jobId}_${networkName}_aggregated_calculated_balances`
  };
  const errorOutputfileDownloadParams = {
    network: networkName,
    fileType: FileType.error,
    jobId: jobId,
    fileName: `${jobId}_${networkName}_validation_errors`
  };
  const uploadfileDownloadParams = {
    network: networkName,
    fileType: FileType.uploadFile,
    jobId: jobId,
    fileName: `${jobId}_${networkName}_uploaded_file`
  };

  const handleCopyClick = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <RowOfTable>
      <StyledTableCell title={jobId}>
        {isCopied && (
          <StyledAlert icon={false} severity="success">
            Copied!
          </StyledAlert>
        )}
        <CopyToClipboard onCopy={handleCopyClick} text={jobId}>
          <Box sx={{ cursor: 'pointer', display: 'inline' }}>
            {jobId.substring(0, 4) + '....' + jobId.substring(jobId.length - 4, jobId.length)}
          </Box>
        </CopyToClipboard>
      </StyledTableCell>
      <StyledTableCell className="file">
        <Box
          sx={
            uploadedFilePath
              ? {
                  cursor: 'pointer',
                  display: 'inline-flex'
                }
              : {}
          }
          onClick={() => fileService.blobDownload(uploadfileDownloadParams)}>
          <Box
            sx={
              uploadedFilePath
                ? {
                    textDecoration: 'underline',
                    color: 'blue'
                  }
                : {}
            }>
            {file}
          </Box>
          {uploadedFilePath && <DownloadIcon fontSize="small" />}
        </Box>
      </StyledTableCell>
      <StyledTableCell>{SUPPORTED_ASSETS_NETWORK_NAME[networkName]}</StyledTableCell>
      <StyledTableCell>{networkName}</StyledTableCell>
      <StyledTableCell>{formattedDateWithTimezone}</StyledTableCell>
      <StyledTableCell>{blockHeight}</StyledTableCell>
      <StyledTableCell>{walletCount}</StyledTableCell>
      <StyledTableCell>
        <WorkStatusToast status={status} />
      </StyledTableCell>
      <StyledTableCell align="right">
        {status === OverAllStatus.INVALID_TEMPLATE || status === OverAllStatus.EMPTY_FILE ? (
          <FileUploader
            handleChange={handleFileUpload}
            name="file"
            types={fileTypes}
            fileOrFiles={file}
            maxSize={500}>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    height: '20px',
                    width: '150px',
                    fontSize: '12px',
                    backgroundColor: '#2E2E38',
                    borderRadius: '0px',
                    paddingTop: '10px',
                    textAlign: 'center',
                    '& .MuiTooltip-arrow': {
                      color: '#2E2E38'
                    }
                  }
                }
              }}
              title="Replace with corrected file."
              arrow
              placement={'top'}>
              <CloudUploadOutlinedIcon sx={{ cursor: 'pointer' }} />
            </Tooltip>
          </FileUploader>
        ) : status === OverAllStatus.INVALID_CONTENT ? (
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
            {errorOutputPath ? (
              <DownloadButton blobDownloadParams={errorOutputfileDownloadParams} />
            ) : null}
            <FileUploader
              handleChange={handleFileUpload}
              name="file"
              types={fileTypes}
              fileOrFiles={file}
              maxSize={500}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      height: '20px',
                      width: '150px',
                      fontSize: '12px',
                      backgroundColor: '#2E2E38',
                      borderRadius: '0px',
                      paddingTop: '10px',
                      textAlign: 'center',
                      '& .MuiTooltip-arrow': {
                        color: '#2E2E38'
                      }
                    }
                  }
                }}
                title="Replace with corrected file."
                arrow
                placement={'top'}>
                <CloudUploadOutlinedIcon sx={{ cursor: 'pointer' }} />
              </Tooltip>
            </FileUploader>
          </Box>
        ) : status === OverAllStatus.COMPLETED ? (
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <DownloadButton />
            <DownloadOutputOptions
              standardOutputParams={stdOutputfileDownloadParams}
              aggregateOutputParams={aggregateOutputfileDownloadParams}
            />
          </div>
        ) : status === OverAllStatus.FAILED ? (
          <Typography sx={{ fontSize: '12px', padding: '17px 0 17px 0' }}>
            The job failed on the server. Please retry.
          </Typography>
        ) : (
          <></>
        )}
      </StyledTableCell>
    </RowOfTable>
  );
};

export default TableRow;
